.header {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1em;
}

.header-company-info {
  display: flex;
  align-items: center;
}

.header-logo {
  background-image: url("/public/assets/logo.svg");
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
}

.header-title {
  font-family: "Open Sans", sans-serif;
  color: #1d67bd;
  font-size: 25px;
  line-height: 25px;
  font-weight: 600;
  text-transform: lowercase;
  margin: 0 0 0 6px;
}

.header-language-select {
  display: none;
}

.nav-bar {
  min-height: 0 !important;
}

#menu-appbar .MuiPaper-root {
  background-color: #192024;
}

.nav-link-mobile {
  text-decoration: none;
}

.nav-link {
  padding: 0.6em 1em;
  color: #fff;
  text-decoration: none;
}

.nav-link:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.2);
}

.nav-link-active {
  padding: 0.6em 1em;
  color: #fff;
  text-decoration: none;
  background-color: #338a3e;
}

.page-title {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 2.5em;
}

.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.carousel {
  padding: 10px 10px 0;
  border-radius: 4px;
  width: 375px;
  margin: auto;
}

.carousel__main {
  border-radius: 4px;
  height: 110px;
}

.carousel-item {
  height: 50vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.dark-background {
  width: 100%;
  background-color: #0b2d45;
  color: #fff;
  padding: 2em 0;
}

.light-background {
  width: 100%;
  background-color: #edeff2;
}

.page-content {
  padding: 0 2em 2em 2em;
  margin: 0 auto;
}

.code {
  display: flex;
  width: 75%;
  justify-content: center;
  flex-direction: column;
}

p {
  margin-top: 0.9em;
  font-weight: 200;
  line-height: 1.6em;
}

.section-heading.dark {
  color: #676770;
  font-size: 2em;
  font-weight: 300;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.section-heading.light {
  color: #fff;
  font-size: 2em;
  font-weight: 300;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.section-row {
  display: flex;
  justify-content: space-around;
  max-width: 100%;
  flex-wrap: wrap;
}

.section-card-2 {
  width: 50%;
  min-width: 300px;
  margin: 10px;
  flex: 1 1;
  overflow: hidden;
  background-size: 100%;
}

.section-card-2-header {
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: 400;
  margin: 0px 0 0.25em 0;
  display: flex;
  justify-content: center;
}

.section-card-3 {
  border: 1px solid #67829e;
  width: 33%;
  min-width: 16em;
  margin: 10px;
  flex: 1;
  padding: 30px;
  overflow: hidden;
  border-radius: 5px;
}

.section-card-3:hover {
  border: 1px solid #0b2d45;
  background-color: rgba(103, 130, 158, 0.1);
  cursor: pointer;
}

.section-card-icon {
  height: 90px;
  width: 90px;
  background-repeat: no-repeat;
  background-size: 100%;
  margin: auto;
}

#icon-cloud {
  background-image: url("/public/assets/cloud.svg");
}

#icon-apps {
  background-image: url("/public/assets/apps.svg");
}

#icon-database {
  background-image: url("/public/assets/database.svg");
}

a,
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.zoom-img {
  border: 1px solid #fff;
  border-radius: 0;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0px 1px 7px 1px rgb(0 0 0 / 10%);
  background-color: #fff;
}

.zoom-img img {
  width: 110%;
  aspect-ratio: 4 / 3;
  object-fit: cover;
  transition: all 0.3s ease;
  margin: -20px;
}

.services-img img {
  width: 100%;
  aspect-ratio: 432 / 257;
  object-fit: cover;
  transition: all 0.3s ease;
  margin: 15px 20px 0 0;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 20%);
}

.services-img img:hover {
  transform: scale(1.05);
}

.zoom-img img:hover {
  transform: scale(1.1);
}

.footer-card-title {
  margin: 10px 0;
  color: #676770;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.footer-card-2 {
  width: 50%;
  min-width: 300px;
  flex: 1 1;
  padding: 20px;
  overflow: hidden;
  border-radius: 5px;
  text-align: justify;
}

.services-row {
  display: flex;
  justify-content: center;
  max-width: 1500px;
  flex-wrap: wrap;
  margin-top: 1em;
}

.services-image {
  min-width: 300px;
  width: 25%;
}

.services-description {
  width: 60%;
  padding: 0 2em;
}

.services-title {
  font-size: 1.4em;
}

.services-body {
  margin: 0 0 2em 0;
}

.services-body p {
  margin-top: 0.4em;
}

.article-title {
  font-weight: 400;
  font-size: 1.4em;
  margin-top: 0.75em;
}

.article-description {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 200;
  margin: -0.75em 0 2em 0;
}

.article-code {
  max-height: 400px;
  -webkit-user-select: text; /* Safari */
  -ms-user-select: text; /* IE 10+ */
  user-select: text;
  cursor: text;
  margin: 2em 0;
}

.article-img {
  display: flex;
  width: 100%;
}

.article-paging {
  margin: 1em auto;
  justify-content: center;
}

.pagination-text {
  font-size: 15px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.pagination-text-selected {
  font-size: 15px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #0b2d45;
  color: #fff;
}

.pagination-pages {
  display: none;
}

.pagination-mobile {
  display: none;
}

@media only screen and (min-width: 600px) {
  .pagination-pages {
    display: flex;
  }
}

@media only screen and (max-width: 599px) {
  .pagination-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
  }

  .footer-card-2 {
    padding: 10px 10px 10px 0;
  }
}

@media (min-width: 900px) {
  .header-language-select {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}

@media only screen and (max-width: 900px) {
  .services-row:nth-child(odd) {
    background: #ffffff;
  }

  .services-row:nth-child(even) {
    background: #3a5670;
    color: #fff;
  }

  .services-image {
    min-width: initial;
    width: 100%;
    margin: 2em;
  }

  .services-img img {
    margin: 0;
    box-shadow: none;
  }

  .services-description {
    width: 100%;
    padding: 0 2em;
  }
}

@media (min-width: 1250px) {
  .page-content {
    width: 1200px;
  }
}

@media (max-width: 1250px) {
  .page-content {
    width: 100%;
  }
}

.error-404-page {
  text-align: center;
}

.error-404-message {
  color: rgba(0, 0, 0, 0.2);
  font-size: 3vw;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  margin: 0.5em 0;
  font-weight: 600;
}

.error-404-background {
  font-size: 20vw;
  color: rgba(0, 0, 0, 0.1);
  line-height: 1em;
}

@media (max-width: 700px) {
  .error-404-background {
    font-size: 25vw;
  }

  .error-404-message {
    font-size: 6vw;
  }
}

.ul-content,
.ol-content {
  margin: 1em 2em;
  display: flex;
  font-weight: 200;
  font-size: 0.95em;
}

.ol-content li {
  margin-bottom: 0.5em;
}

.country-flag {
  width: 1.5em;
  margin: 0 0.5em 0 0;
}
